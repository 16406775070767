<template>
  <div class="headmenu">
    <div class="header-box">
      <div class="header container">
        <!-- logo -->
        <div class="logo">
          <router-link class="logoto" to="/"></router-link>
        </div>

        <!-- 列表 -->
        <ul class="ullist">
          <li class="linav" :class="$route.path === item.path ? 'active' : ''" v-for="(item, index) in headlist"
            :key="index">
            <router-link :to="item.path">
              <span>{{ item.title }}</span>
            </router-link>
            <span style="margin-left: 10px" class="iconfont icon-xiajiantou" v-if="item.subMenu"></span>
            <ul class="subul" v-if="item.subMenu">
              <li class="subli" v-for="(subItem, subIndex) in item.subMenu" :key="subIndex">
                <router-link :to="subItem.path">
                  <span>{{ subItem.title }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>

        <!-- 小屏九宫格 -->
        <div class="ninenav" @click="tofathershowtabm">
          <span class="iconfont icon-jiugongge"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["headlist"],
  methods: {
    tofathershowtabm() {
      this.$emit("showtabm");
    },
  },
};
</script>

<style lang="scss">
// 头部导航栏  headMenu.vue
.headmenu {
  height: 10rem;
  // background-color: #28a745;

  /* 固定定位 */
  .header-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 10rem; // 固定定位的父容器
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

    .header {
      height: 10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .elemeubox {
        .el-menu.el-menu--horizontal {
          border-bottom: 0;
        }

        .el-menu--horizontal > .el-menu-item {
          font-size: 18px;
          font-weight: 700;
          color: #000;
        }
        .el-submenu__title {
          font-size: 18px;
          font-weight: 700;
          color: #000;
        }
      }

      // logo
      .logo {
        width: 24rem;
        margin-right: 200px;

        // a {
        .logoto {
          background: url(../assets/imgs/ICSR_logo.png) no-repeat center center;
          width: 10rem;
          height: 7rem;
          // width: 180px;
          // height: 50px;
          display: inline-block;
          background-size: contain;
        }
      }

      // 列表
      .ullist {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        .linav {
          margin: 0 2rem;
          line-height: 10rem; //add
          position: relative;

          &::before {
            content: "";
            position: absolute;
            // bottom: -2rem;
            bottom: 2rem;
            left: 50%;
            width: 0;
            border-bottom: 3px solid var(--bgcolor);
            transition: all 0.2s;
          }

          &:hover::before {
            content: "";
            width: 100%;
            left: 0;
          }

          span {
            font-size: 1.6rem;
            font-weight: 700;

            &:hover {
              color: var(--bgcolor);
            }
          }
        }

        // 导航激活样式
        .active {
          a {
            color: var(--bgcolor);
            font-weight: bold;
          }

          // span {
          //     padding-bottom: 18px;
          //     border-bottom: 3px solid var(--bgcolor);
          // }
        }
      }

      .linav:hover {
        .subul {
          display: block;
        }
      }

      // 二级列表
      .subul {
        display: none;
        position: absolute;
        left: 0;
        top: 100px;
        background-color: #ffffff;
        width: 190px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        .subli {
          padding: 20px 25px;
          // background-color: #e9f5ff;
          line-height: 1;
          a {
            color: #333;
          }
        }
        .subli:hover {
          background-color: #e9f5ff;
          a {
            color: var(--bgcolor);
          }
        }
      }

      // 小屏九宫格
      .ninenav {
        display: none;
        padding-right: 2rem;

        .iconfont {
          font-size: 3.4rem;
          color: var(--bgcolor);

          &:hover {
            cursor: pointer;
            color: var(--blue);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  // 头部导航栏
  .headmenu {
    height: 9rem;

    .header-box {
      height: 9rem;
      padding: 10px 0;

      .header {
        height: 7rem;
        padding-left: 12px;

        .logo {
          // 权重
          .logoto {
            width: 7.5rem;
          }
        }

        .ullist {
          display: none;
        }

        .ninenav {
          display: block;
        }
      }
    }
  }
}
</style>
