<template>
  <div class="homebox">
    <!-- 背景部分 -->
    <div class="homebgbox">
      <div class="homebg center">
        <!-- <img src="@/assets/imgs/ICSR_logo.png" alt="" class="logo_img"> -->
        <div class="bgtext container">
          <div class="datebox">
            <div class="date date1 wow fadeInDown">location : Singapore</div>
            <div class="date date2 wow fadeInDown">
              date ： August 16-18, 2024
            </div>
          </div>
          <!-- <div class="bgtitle wow fadeInUp">
            16th International Conference on Social Robotics + Biotech + Medicine
          </div> -->
          <div class="bgtitle wow fadeInUp">
            16th International Conference on Social Robotics + BioMed
          </div>
          <div class="bgtitle bgtitle2 wow fadeInUp">
            (ICSR + BioMed)
          </div>
          <div class="bgdesc wow fadeInLeft">
            Organized by GRASS, and technically supported by IEEE Singapore SMC
            Chapter.
          </div>
        </div>
      </div>
    </div>

    <!-- 首页主体 -->
    <div class="home container">
      <div class="mainbox">
        <section class="sec2 wow fadeInLeft">
          <div class="textbox">
            <h2>OBJECTIVES</h2>
            <div class="text">
              <div>
                The 16th International Conference on Social Robotics + BioMed (ICSR + BioMed 2024) focuses on
                interdisciplinary innovation on Bio-inspired, Biomedical, and
                Surgical Robotics. By fostering the much needed merging of these
                disciplines, together with fast emerging Biotech, the conference
                aims to ensure the lesson learned from these communities blend
                together to unleash the real potential of robots. It will take
                place in Singapore as a face-to-face conference on August 16-18,
                2024.
              </div>
              <div class="detail-text">
                The conference will serve as the scientific, technical and
                business platform for fostering collaboration, exploration, and
                advancement in these cutting-edge fields. It will showcase the
                latest breakthroughs and methodologies, shaping the future of
                robotics design and applications across several sectors
                including Biomedical and healthcare.
              </div>
            </div>
          </div>
        </section>

        <h2 class="mainbox-title wow fadeInLeft">
          ICSR 2024 Conference Details and Opportunities
        </h2>

        <div class="sec1box">
          <section class="sec1">
            <ul class="ulnav">
              <li v-for="(v, i) in sectionul1" :key="i" :class="{ navactive: showsec1 == i }" @click="secShow1(i)">
                <span class="iconfont" :class="v.iconfont"></span>
                <div>{{ v.title }}</div>
              </li>
            </ul>

            <div class="contentbox">
              <div class="content">
                <div class="lefttopimg">
                  <div class="ltimg">
                    <span class="iconfont icon-SVGtubiao-zhuanmian-20"></span>
                  </div>
                </div>

                <div class="textbox">
                  <div class="wow fadeInUp" v-for="(v, i) in sectioncon1" :key="i" v-show="showsec1 == i">
                    <h2>{{ v.title }}</h2>
                    <div class="ctext">{{ v.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section class="topics">
          <h2 class="mainbox-title wow fadeInUp">Conference Topics</h2>
          <ul class="topicsul wow fadeInLeft" data-wow-delay="0.2s">
            <li>Bio-robots in biomedical and healthcare applications.</li>
            <li>Biomimics technology in robots and medical instrument.</li>
            <li>BCI application in Healthcare and rehabilitation application.</li>
            <li>EEG, EMG based interaction to assist persons with disabilities.
            </li>
            <li>Affective and cognitive sciences for diagnosis.</li>
            <li>AI technology in rehabilitation evaluation.</li>
            <li>Context awareness, expectation, and intention understanding in
              biomedical and healthcare application.
            </li>
            <li>Control strategies and architectures for biomedical and healthcare
              robot.
            </li>
            <li>AI + Algorithms in Dentistry and Orthopedics Clinics.</li>
            <li>Software frameworks for biomedical and healthcare.</li>
            <li>Teleoperation, telepresence and remote interaction in medical
              robots.
            </li>
            <li>Human augmentation, rehabilitation, and medical robots.</li>
            <li>Interaction among biomedical robots, humans and environments.
            </li>
            <li>Collaboration among biomedical robots.</li>
            <li>Surgery robots and AI technology.</li>
            <li>Virtual Reality, Augmented Reality, X-Reality and Digital twin in medical and ealthcare application.
            </li>
            <li>Multi-functional in human-robot interaction.</li>
            <li>AI technology in bioinspired robots design.</li>
            <li>Bio-Robots in wild life and city environment.</li>
            <li>Robot applications in medicine education, healthcare, welfare, and rescue.
            </li>
            <li>Soft Robotics for Medicine.</li>
            <li>Robots to assist the elderly and persons with disabilities.</li>
            <li>AI in medical diagnosis and image processing.</li>
            <li>Organ chips in medical treatment.</li>
            <li>Medical assistant instruments to improve quality of treatment.</li>
            <li>Social Robotics and Digital Robotics.</li>
            <li>Robotics and Arts.</li>
            <li>Human Robotics Interaction and Fusion.</li>
          </ul>
        </section>

        <section class="box4">
          <p class="work-title wow fadeInLeft">Important Dates:</p>
          <div class="lrulbox">
            <ul class="ulbox">
              <li class="wow fadeInUp" data-wow-delay="0.2s">
                <div class="worktitle">Paper Submission：</div>
                <!-- <div class="worktitle" style="text-decoration:line-through;">Paper Submission：</div> -->
                <div class="workdate" style="text-decoration:line-through;">June 25, 2024</div>
                <!-- <div class="worktitle" style="color:#e05e5e;">Extended Paper Submission: 	</div> -->
                <div class="workdate" style="color:#e05e5e;">July 5, 2024</div>
              </li>
              <li class="wow fadeInUp" data-wow-delay="0.2s">
                <div class="worktitle">Paper Notification：</div>
                <div class="workdate" style="text-decoration:line-through;">July 5, 2024</div>
                <div class="workdate" style="color:#e05e5e;">August 1, 2024</div>
              </li>
              <li class="wow fadeInUp" data-wow-delay=".4s">
                <div class="worktitle">Final Submission：</div>
                <div class="workdate" style="text-decoration:line-through;">July 16, 2024</div>
                <div class="workdate" style="color:#e05e5e;">August 5, 2024</div>
              </li>
            </ul>

            <ul class="ulbox">
              <li class="wow fadeInUp" data-wow-delay="0.4s">
                <div class="worktitle">Workshop Proposal Submission：</div>
                <div class="workdate" style="text-decoration:line-through;">July 5, 2024</div>
                <div class="workdate" style="color:#e05e5e;">July 30, 2024</div>
              </li>
              <li class="wow fadeInUp" data-wow-delay="0.6s">
                <div class="worktitle">Workshop Proposal Notification：</div>
                <div class="workdate" style="text-decoration:line-through;">July 11, 2024</div>
                <div class="workdate" style="color:#e05e5e;">August 5, 2024</div>
              </li>
              <li class="wow fadeInUp" data-wow-delay=".6s">
                <div class="worktitle">Exhibition Submission：</div>
                <div class="workdate" style="text-decoration:line-through;">July 5, 2024</div>
                <div class="workdate" style="color:#e05e5e;">July 30, 2024</div>
              </li>
              <li class="wow fadeInUp" data-wow-delay=".8s">
                <div class="worktitle">Competition Notification：</div>
                <div class="workdate" style="text-decoration:line-through;">July 11, 2024</div>
                <div class="workdate" style="color:#e05e5e;">August 5, 2024</div>
              </li>
            </ul>
          </div>

        </section>

        <section class="box5">
          <p class="pleasebtn wow fadeInLeft">Paper Submission Link:</p>
          <div class="link-wrapper">
            <a class="link4" href="https://equinocs.springernature.com/service/ICSR2024B" target="_blank"
              style="font-style: italic;">https://equinocs.springernature.com/service/ICSR2024B</a>
          </div>
        </section>

        <section class="box6">
          <p class="pleasebtn wow fadeInLeft">Registration Link:</p>
          <div class="link-wrapper">
            <a class="link4" href="https://reg.eventnook.com/event/icsr24singapore" target="_blank"
              style="font-style: italic;">https://reg.eventnook.com/event/icsr24singapore</a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectionul1: [
        { iconfont: "icon-duigou2", title: "Special Sessions" },
        { iconfont: "icon-tijiao", title: "Submission of Papers" },
        { iconfont: "icon-shishishujuzhanshi", title: "Workshops" },
      ],
      sectioncon1: [
        {
          content:
            "The ICSR 2023 Technical Programme will include Special Sessions. Their objective is to complement the regular program with new and/or emerging topics of particular interest. Proposals will be evaluated, and organizers will be informed of the outcome. Once accepted, the special session will be publicized in ICRS website together with invited session code, so prospective authors can submit. Papers will undergo the same review process as regular papers.",
          title: "Special Sessions",
        },
        {
          content:
            "Please submit your full manuscript in PDF format according to Springer Publishing's submission guidelines , prepared in LaTex or Word, and no longer than 10 pages in length, including references.All papers will be refereed by the program committee.",
          title: "Submission of Papers",
        },
        {
          content:
            "Participants are invited to hold workshops on topics relevant to social robotics. Proposals on workshops will be considered by the organizing committee.",
          title: "Workshops",
        },
      ],
      showsec1: 1,
    };
  },
  methods: {
    secShow1(i) {
      this.showsec1 = i;
    },
  },

  mounted() {
    // new this.$wow.WOW().init()
    // 或者
    var wow = new this.$wow.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
.homebox {

  // 首页背景部分
  .homebgbox {
    // height: calc(100vh - 10rem);
    width: 100%;
    height: 84vh;
    margin-bottom: 10rem;

    .homebg {
      width: 100%;
      height: 100%;
      background: url(../../assets/imgs/pic1.jpeg) no-repeat center/cover;
      text-align: center;
      // position: relative;

      // .logo_img{
      //   width: 200px;
      //   height:auto;
      //   position: absolute;
      //   // top: 20%;
      //   // left: 7%;
      //   top: 20px;
      //   left: 7px;
      // }
      .container {
        max-width: 1400px;
      }

      .bgtext {
        // max-width: 1140px;
        padding: 0 2rem;

        .datebox {
          width: 34rem;
          margin: auto;
        }

        .date {
          color: var(--bgcolor);
          font-size: 20px;
          font-weight: 500;
          text-transform: uppercase;
          line-height: 3rem;
          text-align: left;
          margin-top: 8px;
        }

        .date1::before {
          content: "\e601";
          font-family: "iconfont";
          padding-right: 20px;
        }

        .date2::before {
          content: "\e9aa";
          font-family: "iconfont";
          padding-right: 20px;
        }

        .bgtitle {
          color: #ffffff;
          font-size: 6.2rem;
          font-weight: 800;
          line-height: 9rem;
          letter-spacing: 2.2px;
          margin: 5rem 0 2rem;
        }

        .bgtitle2 {
          font-size: 4.4rem;
          margin: 0rem;
        }

        .bgdesc {
          color: #ffffff;
          font-size: 29px;
          font-weight: 400;
          line-height: 41px;
          letter-spacing: 1.3px;
          text-shadow: -2px -5px 23px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  // 首页主体
  .home {
    .mainbox {
      padding: 2rem 3rem;

      .sec2 {
        .textbox {
          h2 {
            color: #101010;
            margin: 10px 0px 35px 0px;
            font-family: "Poppins", Sans-serif;
            font-size: 48px;
            font-weight: 700;
          }

          .text {
            color: #000;
            font-family: "Rubik", Sans-serif;
            font-size: 17px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0.21px;
            margin: 0px 0px 70px 0px;
            text-align: justify;

            .detail-text {
              color: var(--bgcolor);
            }
          }
        }
      }

      .mainbox-title {
        color: #101010;
        margin-bottom: 7rem;
        font-size: 48px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: -1.92px;
        text-align: center;
      }

      .sec1box,
      .sec11box {
        // background-color: skyblue;
        height: 600px;

        @media (max-width: 768px) {
          height: 1000px;

          .sec1 {
            flex-direction: column;

            .ulnav {
              margin-right: 0rem !important;
            }
          }
        }

        .sec1 {
          height: 100%;
          display: flex;
          justify-content: space-between;
          // background-color: #ccc;

          // 三个标题
          .ulnav {
            flex: 1;
            position: relative;
            color: var(--bgcolor);
            margin-right: 6rem;

            .navactive {
              color: #fff;
              background-color: var(--bgcolor);
            }

            li {
              position: absolute;
              padding: 77px;
              font-size: 2rem;
              text-align: center;
              border-radius: 5px;
              box-shadow: 20px 0px 40px 0px rgba(0, 0, 0, 0.1);

              .iconfont {
                font-size: 5.2rem;
                display: inline-block;
                margin-bottom: 1.2rem;
              }

              @media (max-width: 768px) {
                position: static;
                padding: 40px 60px;
                font-size: 1.6rem;

                .iconfont {
                  font-size: 2.2rem;
                  margin-bottom: 1.2rem;
                }
              }
            }

            li:nth-child(2) {
              left: 210px;
              top: 116px;
              z-index: 1;
            }

            li:nth-child(3) {
              left: 50px;
              top: 320px;
              z-index: 1;
            }
          }

          // 显示内容
          .contentbox {
            flex: 1;
            padding-top: 8rem;

            @media (max-width: 768px) {
              padding-top: 0rem;
            }

            .content {
              position: relative;

              .lefttopimg {
                position: absolute;
                top: -1rem;
                left: -3.5rem;
                width: 100px;
                height: 100px;

                .ltimg {
                  width: 70%;

                  // filter: brightness(99%) contrast(89%) saturate(100%) blur(0px) hue-rotate(0deg);
                  .iconfont {
                    font-size: 10rem;
                    color: #ebebeb;
                  }
                }
              }

              // 内容
              .textbox {
                position: relative;
                padding-top: 2rem;

                h2 {
                  color: #101010;
                  font-family: "Poppins", Sans-serif;
                  font-size: 48px;
                  font-weight: 700;
                  line-height: 54px;
                  margin-bottom: 2rem;
                }

                .ctext {
                  color: #666666;
                  font-family: "Roboto", Sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 26px;
                  text-align: justify;
                }
              }
            }
          }
        }
      }

      .sec11box {
        margin-top: 12rem;
      }

      // 会议主题
      .topics {
        .topicsul {
          background: rgba(56, 113, 151, 0.2);
          padding: 40px 30px;

          li {
            color: #666666;
            font-family: "Roboto", Sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
            text-align: justify;

            &::before {
              content: "\e6a8";
              font-family: "iconfont";
              // color: #8A1538;
              color: var(--bgcolor);
              padding-right: 10px;
              font-size: 20px;
            }
          }
        }
      }

      .box4 {
        padding-bottom: 3rem;
        margin-top: 6rem;

        .work-title {
          color: #101010;
          font-family: "Poppins", Sans-serif;
          font-size: 40px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: -1.92px;
        }

        .lrulbox {
          display: flex;
        }

        .ulbox {
          // display: flex;
          // flex-direction: column;
          flex: 1;
          flex-wrap: wrap;
          margin-top: 6rem;
          gap: 2rem 3rem;

          li {
            width: calc(100% - 2rem);
            background: var(--gray);
            // background: rgba(56, 113, 151, 0.2);
            padding: 20px;

            .worktitle {
              color: var(--bgcolor);
              font-family: "Rubik", Sans-serif;
              font-size: 28px;
              font-weight: 400;
              text-transform: capitalize;
              line-height: 40px;
              letter-spacing: 0.9px;
              word-spacing: 0px;
              margin-bottom: 2rem;
            }

            .workdate {
              font-family: "Rubik", Sans-serif;
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
              text-align: justify;

              &::before {
                content: "\e9aa";
                font-family: "iconfont";
                color: var(--bgcolor);
                padding-right: 20px;
              }
            }
          }
        }
      }

      .box5,.box6 {
        margin-bottom: 20px;

        .pleasebtn {
          color: #101010;
          font-family: "Poppins", Sans-serif;
          font-size: 36px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: -1.92px;
        }

        .link-wrapper {
          margin-top: 20px;

          .link4 {
            color: #8d1b3d;
            font-size: 26px;
          }
        }

        @media (max-width: 768px) {
          .pleasebtn {
            font-size: 30px;
          }

          .link-wrapper {
            line-height: 180%;
            margin-top: 6px;

            .link4 {
              border-bottom: 1px solid #8d1b3d;
              word-wrap: break-word;
              font-size: 22px;
            }
          }
        }

        @media (min-width: 770px) {
          .link4 {
            border: 1px solid #8d1b3d;
            padding: 8px 14px;
            border-radius: 10px;
          }
        }

      }


    }
  }

  @media (max-width: 768px) {
    .homebgbox {
      width: 100%;
      height: 100%;
      margin-bottom: 6rem;

      .homebg {
        .bgtext {
          padding: 7rem 2rem;

          .datebox {
            width: 32rem !important;
          }

          .date {
            color: var(--bgcolor);
            font-size: 1.8rem;
          }

          .bgtitle {
            font-size: 4rem;
            line-height: 6rem;
            margin: 4rem 0;
          }

          .bgtitle2 {
            font-size: 28px;
          }

          .bgdesc {
            font-size: 24px;
          }
        }
      }
    }

    .home {
      .mainbox {
        h2 {
          font-size: 38px !important;
          line-height: 50px;
        }

        .box4 {
          margin-top: 0rem;

          .lrulbox {
            display: flex;
            flex-direction: column;
          }

          .ulbox {
            margin-top: 3rem;
            gap: 3rem;

            li {
              width: calc(100% - 0rem);

              .worktitle {
                font-size: 26px;
              }
            }
          }
        }

        // 会议主题
        .topics {
          .mainbox-title {
            margin-bottom: 4rem;
          }

          .topicsul {
            padding: 25px 18px;
            margin-bottom: 40px;

            li {
              font-size: 16px;
              line-height: 30px;

              &::before {
                content: "\e6a8";
                font-family: "iconfont";
                // color: #8A1538;
                color: var(--bgcolor);
                padding-right: 10px;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
