import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import 'element-ui/lib/theme-chalk/index.css';
import { Button,Menu,Submenu,MenuItem, } from 'element-ui';
Vue.use(Button)
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// Vue.use(VueLazyload, {
//   // 懒加载默认加载图片
//   loading:require('@/assets/imgs/loading.png'),
//   // 加载失败后加载的图片
//   // error: 'xxx.png',
//   preLoad: 1.3, // 预加载高度的比例
//   attempt: 3 // 尝试加载次数
// })

// 引入公共样式
import '@/assets/css/common.css'
import "@/assets/css/index.scss"

// 引入阿里的字体图标
import "@/assets/fonts/iconfont.css"

// 引入wowjs
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow

Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
