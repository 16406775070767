<template>
  <div class="footerbox">
    <div class="footer container">
      <div class="footer-main center">
        <div class="logo">
          <img src="../assets//imgs//ICSR_logo.png" alt="">
        </div>
        <div class="text">
          The 16th International Conference on Social Robotics + BioMed (ICSR + BioMed 2024) focuses on
          interdisciplinary innovation on Bio-inspired, Biomedical, and Surgical Robotics. It will take place in
          <span class="date">Singapore</span>,
          <span class="date">August 16-18, 2024.</span>
        </div>

      </div>

      <div class="footer-email">
        <div>For any inquiries related to the conference, please feel free to reach out to us via email: <span
            class="email">ICSRbiomed@gmail.com</span> </div>
        <div>We look forward to hearing from you!</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footerbox {
  background: var(--gray);

  // background: rgba(56, 113, 151, 0.8);
  .footer {
    padding: 10rem;

    @media (max-width: 768px) {
      padding: 4rem 2rem;

      .footer-main {
        flex-direction: column;

        .logo {
          width: 75% !important;
        }
      }
    }

    .footer-main {
      text-align: center;

      .logo {
        border-radius: 16px;
        margin-right: 30px;
        width: 37%;
      }

      .text {
        margin-top: 3rem;
        max-width: 500px;
        color: #666666;
        font-family: "Rubik", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 2.2em;

        .date {
          color: #8d1b3d;
          font-size: 2rem;
        }
      }
    }

    .footer-email {
      text-align: center;
      color: #666666;
      font-family: "Rubik", Sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 2.2em;
      .email {
        color: #8d1b3d;
        font-size: 2rem;
        font-style: italic;
      }
    }
  }
}
</style>