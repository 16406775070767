import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
  },
  {
    path: '/program',
    name: 'program',
    component: () => import('../views/program'),
    meta: {
      title: 'program', 
    },
  },
  {
    path: '/committee',
    name: 'committee',
    component: () => import('../views/committee'),
    meta: {
      title: 'Committee', 
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/registration'),
    meta: {
      title: 'registration', 
    },
  },
  {
    path: '/submission',
    name: 'submission',
    component: () => import('../views/paper'),
    meta: {
      title: 'submission', 
    },
  },
  {
    path: '/venue',
    name: 'venue',
    component: () => import('../views/venue/index.vue'),
    children:[
      {
        path: '/venue',
        name: 'Venue',
        component: () => import('../views/venue/venue.vue')
      },
      {
        path: '/about',
        name: 'about Singapore',
        component: () => import('../views/about')
      },
      {
        path: '/hotels',
        name: 'hotels',
        component: () => import('../views/hotels')
      },

    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

const defaultTitle = "The 16th International Conference on Robotics + Biotech + Medicine (ICSR + BioMed 2024)";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title + "  "+ "-" + "  "+"robicon2024" : defaultTitle;
  next();
});

export default router
