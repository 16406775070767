<template>
  <div id="app">
    <head-menu @showtabm="istabmshow = true" :headlist="headlist"></head-menu>
    <router-view />
    <footer-com></footer-com>

    <!-- 手机左侧菜单栏 -->
    <div class="tabm" v-if="istabmshow">
      <div class="top">
        <div class="logo">
          <router-link to="/"></router-link>
        </div>
        <span class="iconfont icon-guanbi" @click="istabmshow = false"></span>
      </div>
      <div class="tabbox">
        <ul class="ullist">
          <!-- @click="istabmshow = false" 之前的 已舍弃 -->
          <li
            class="linav"
            :class="$route.path === item.path ? 'active' : ''"
            v-for="(item, index) in headlist"
            :key="index"
            @click="istabmshowBtn(item)"
          >
            <router-link :to="item.path">
              <span>{{ item.title }}</span>
            </router-link>
            <span
              style="margin-left: 10px"
              class="iconfont icon-xiajiantou"
              v-if="item.subMenu"
              @click="istamsubshow"
            ></span>
            <ul class="subul" v-if="item.subMenu">
              <li
                class="subli"
                v-for="(subItem, subIndex) in item.subMenu"
                :key="subIndex"
                @click="istabmshow = false"
              >
                <router-link :to="subItem.path">
                  <span>{{ subItem.title }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="tabm" v-if="istabmshow">
      <div class="top">
        <div class="logo">
          <router-link to="/"></router-link>
        </div>
        <span class="iconfont icon-guanbi" @click="istabmshow = false"></span>
      </div>
      <div class="tabbox">
        <ul class="ullist">
          <li
            class="linav"
            :class="$route.path === v.path ? 'active' : ''"
            v-for="(v, i) in headlist"
            :key="i"
            @click="istabmshow = false"
          >
            <router-link :to="v.path">
              <span>{{ v.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import HeadMenu from "@/components/headMenu.vue";
import FooterCom from "@/components/footer.vue";
export default {
  components: {
    HeadMenu,
    FooterCom,
  },
  data() {
    return {
      showtopBtn: false,
      istabmshow: false,
      // // headlist列表格式
      // headlist: [
      //   { title: "Home", path: "/" },
      //   { title: "Committee", path: "/committee" },
      //   { title: "Paper", path: "/paper" },
      //   {
      //     title: "Venue",
      //     path: "/venue",
      //     subMenu: [
      //       { title: "venue", path: "/venue" },
      //       { title: "hotels", path: "/hotels" },
      //     ],
      //   },
      // ],
      headlist: [],
      tamsubshow: false,
    };
  },
  watch: {
    istabmshow(newVal, oldVal) {
      if (newVal) {
        window.document.documentElement.style.overflow = "hidden";
      } else {
        window.document.documentElement.style.overflow = "visible";
      }
    },
    tamsubshow(newVal, oldVal) {
      // console.log(newVal, "===", oldVal);
      let subli = document.querySelector(".tabm .subul");
      // console.log(subli, 1111111111111);
      if (newVal) {
        subli.style.display = "block";
      } else {
        subli.style.display = "none";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handelRoutes();
  },
  methods: {
    handelRoutes() {
      // let arrRoutes = this.$router.options.routes;
      // // console.log(this.$router.options.routes, 666);  路由表
      // this.headlist = arrRoutes.map((val) => {
      //   return {
      //     title: val.name.charAt(0).toUpperCase() + val.name.slice(1),
      //     path: val.path,
      //   };
      // });

      let arrRoutes = this.$router.options.routes;
      this.headlist = arrRoutes.map((val) => {
        const item = {
          title: val.name.charAt(0).toUpperCase() + val.name.slice(1),
          path: val.path,
        };
        if (val.children && val.children.length > 0) {
          item.subMenu = val.children.map((child) => ({
            title: child.meta
              ? child.meta.title
              : child.name.charAt(0).toUpperCase() + child.name.slice(1),
            path: child.path,
          }));
        }
        return item;
      });
      // console.log(this.headlist, 777777777);
    },
    // 二级选项卡显示和隐藏
    istamsubshow() {
      // console.log("点击了");
      this.tamsubshow = !this.tamsubshow;
    },
    // 一级选项卡才关闭导航栏和跳转
    istabmshowBtn(linav) {
      // console.log(linav, 22222222222);
      if (!linav.subMenu) {
        this.istabmshow = false;
      }
    },
    // handleScroll() {
    //   const sTop = document.documentElement.scrollTop || document.body.scrollTop;
    //   this.showtopBtn = sTop > 300; // 当页面滚动超过 300px 时，显示回到顶部的按钮
    // },

    // 回到顶部
    // toTop() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
#app {
  // 手机左侧菜单栏
  .tabm {
    width: 100%;
    height: 100vh;
    padding: 20px;
    // background: #fff;
    background: #f7f7f7;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      .logo {
        width: 100px;
        height: 100%;
        background: url(../src/assets/imgs/ICSR_logo.png) no-repeat center
          center;
        // background: url(../../assets/imgs/ICSR_logo.png) no-repeat center center;
        background-size: contain;
      }

      .iconfont {
        font-size: 34px;
      }
    }

    .tabbox {
      margin-top: 30px;
      height: calc(100vh - 80px);

      // ul {
      //   li {
      //     line-height: 50px;

      //     span {
      //       font-size: 20px;
      //       font-weight: 600;
      //     }
      //   }

      //   // 导航激活样式
      //   .active {
      //     a {
      //       color: var(--bgcolor);
      //       font-weight: bold;
      //     }
      //   }
      // }

      // 列表
      .ullist {
        .linav {
          line-height: 50px;
          display: flex;
          justify-content: space-between;
          position: relative;

          span {
            font-size: 20px;
            font-weight: 600;
          }
        }

        // 二级列表
        .subul {
          display: none;
          position: absolute;
          left: 0;
          top: 55px;
          background-color: #ffffff;
          // width: 160px;
          width: 100%;
          border-radius: 0 0 8px 8px;
          overflow: hidden;
          .subli {
            padding: 20px;
            // background-color: #e9f5ff;
            line-height: 20px;
            a {
              color: #000;
            }
          }
        }

        // 导航激活样式
        .active {
          a {
            color: var(--bgcolor);
            font-weight: bold;
          }
        }
      }

      .active {
        color: var(--bgcolor);
      }
    }
  }
}
</style>
